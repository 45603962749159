<template>
  <div>
    <label>{{required ? label + '*' : label}}</label>
    <v-row v-for="(item, index) in newValue" :key="index" class="mt-0 d-flex align-center">
      <v-col cols="9" class="py-0">
        <DynamicFormControl 
            :disabled="disabled"
            :control="{
                name: 'Item_' + index,
                type: 'TextInput',
                value: item,
                config: inputConfig
            }"
            @change="updateItem(index, $event)" 
        />
      </v-col>
      <v-col cols="3" class="py-0">
        <v-btn color="#BBBBBB" type="button" icon @click="removeItem(index)" style="margin-top: -8px;" title="Remove Item">
            <v-icon>fa-times</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="!max || (newValue && newValue.length < max)">
      <v-col cols="12" class="text-left">
        <v-btn color="white" type="button" @click="addItem">
          <i class="fas fa-plus-circle"></i>&nbsp;&nbsp;{{addButtonText}}
        </v-btn>
      </v-col>
    </v-row>

    <ErrorMessages :errors="errors"></ErrorMessages>
  </div>  
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'

export default {
  name: 'TextInputList',
  components: {
    DynamicFormControl
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    rules: String,
    label: String,
    disabled: Boolean,
    required: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    inputConfig: {
      type: Object,
      default: () => {}
    },
    addButtonText: {
      type: String,
      default: 'Add Item'
    },
    max: {
      type: Number,
      default: null,
    }
  },
  emits: [
    'change'
  ],
  data() {
    return {
      newValue: []
    }
  },
  methods: {
    addItem() {
      this.newValue.push(null),
      this.emitValue();
    },
    removeItem(index) {
      this.newValue.splice(index, 1);
      this.$forceUpdate();
      this.emitValue();
    },
    updateItem(index, event) {
      this.newValue[index] = event.value;
      this.emitValue();
    },
    emitValue() {
      this.$emit('change', this.newValue);
    }
  },
  watch: {
      value: {
        handler(newVal) {
          if (newVal && newVal.length) {
              this.newValue = newVal;
          } else {
              this.newValue = [];
          }             
        },
        immediate: true,
        deep: true,
      }
  },
}
</script>

<style scoped>
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
</style>